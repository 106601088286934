import React from 'react'
import { APPS } from '../../constants/AbsatApps.js'
import AbsatAppsListItem from './AbsatAppsListItem.jsx'

export const AbsatAppsList = ({tenant}) => {
 

   
    const custApps  = (tenant.apps && tenant.apps.length) > 0 ? tenant.apps : APPS

    return (
        <div className='grid grid-cols-5 gap-7 w-5/6 '>
            {
                custApps.map(app => (
                    <AbsatAppsListItem key={app.name} app={app}/>
                ))
            }
        </div>
    )
}
