import { useEffect,useState } from "react";
import useLicense from "../hooks/useLicense"
import NotificationBarItem from "./NotificationBarItem";
import LicenseBar from "../components/notification-bar/LicenseBar";
import { useCurrent } from "../hooks";

const NotificationBar = () => {
    const {currentCompany} = useCurrent();
    
    //console.log("currentCompany",currentCompany);
    const [isShowLicense,setIsShowLicense] = useState(false);
    const [isShowUpdateVat,setIsShowUpdateVat] = useState(false);
    const isVatNnumberUpdated = JSON.parse(localStorage.getItem('isVatNnumberUpdated') || false);

    // License Handler
    const {data:dataLicense,loading:loadingLicense,licenseDays,TRAIL_PERIOD_DAYS,typeLicense} = useLicense(false);
    
    // License Loader Handler
    useEffect(() => {
        if(dataLicense && !loadingLicense ){
            setIsShowLicense(true);
        }
    },[dataLicense]);

    useEffect(() => {
        if(currentCompany){
            const hasVat = currentCompany.hasVat;
            const vatNumber = currentCompany.vatNumber;
            if(hasVat && !vatNumber && !isVatNnumberUpdated){
                setIsShowUpdateVat(true)
            }
        }
    },[currentCompany]);

    return (
        <>
            {
                isShowLicense && licenseDays <= TRAIL_PERIOD_DAYS && 
                <NotificationBarItem setShow={setIsShowLicense}>
                    <LicenseBar licenseDays={licenseDays} typeLicense={typeLicense}  />
                </NotificationBarItem>     
            }
        </>
    )
}

export default NotificationBar