import { useState } from 'react';
import axios from 'axios';

const usePost = (withHeaderJson=true) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (url, payload) => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        let _headers = {
            headers: withHeaderJson ? {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}` 
            } :{
                'Authorization': `Bearer ${token}`  
            }
        }
        try {
            const response = await axios.post(url, payload,_headers);
            setData(response.data);
            setLoading(false);
            console.log('Post Request:',`URL:` ,url, `PAYLOAD:`,payload,`RESPONE:`,response.data);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { data, loading, error, postData };
};

export default usePost;