export const APPS = [
    {
        name:'sales',
        titleAr:' المبيعات',
        titleEn:'SALES',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img:'https://img.freepik.com/free-photo/sales-sell-selling-commerce-costs-profit-retail-concept_53876-132327.jpg?w=1060&t=st=1708919720~exp=1708920320~hmac=135bacc77a08c31ea688fbe79a12cee5431e9de5fee2f253b01026cf3554a3a1',
        icon: 'icons8-total-sales-96.png',
        baseUrl:'https://sales.absat.org',
        status:'done'
    },
    {
        name:'purchases',
        titleAr:' المشتريات',
        titleEn:'Purchases',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img:'https://img.freepik.com/free-photo/closeup-people-using-digital-tablet_53876-16081.jpg?t=st=1708925356~exp=1708928956~hmac=4f033dd5ab405f47eac66ca162a792091fabd3203767b83a4ce3cc2e45e5986c&w=1060',
        icon:'icons8-purchase-80.png',
        baseUrl:'https://purchases.absat.org',
        status:'done'
    },
    {
        name:'hr',
        titleAr:' شئون الموظفين',
        titleEn:'Human Resources',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img:'https://img.freepik.com/free-photo/human-resources-concept-with-hand_23-2150389097.jpg?t=st=1708924505~exp=1708928105~hmac=dc1b3134e4c860901c08061ae5e1c13a4c7743a1915d9c3575de97f3bace7293&w=1060',
        icon:'icons8-hr-64.png',
        baseUrl:'https://hr.absat.org',
        status:'done'
    },
    {
        name:'rating',
        titleAr:'التقييم',
        titleEn:'ٍRating',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img:'https://img.freepik.com/free-vector/realistic-3d-complain-concept_23-2148950256.jpg?t=st=1708942816~exp=1708946416~hmac=5a20223ef546a5d9206145efe77f78e4d43fb376cc187cbf57a3ab6db41de503&w=1060',
        icon:'icons8-rating-100.png',
        baseUrl:'https://rating-admin.absat.org',
        status:'done'
    },
    {
        name:'store',
        titleAr:' المتجر الالكتروني',
        titleEn:'Store',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img:'https://img.freepik.com/premium-photo/powerlifting-logo-design_946657-2694.jpg?w=1060',
        icon:'icons8-cart-64.png',
        baseUrl:'https://store.absat.org',
        status:'loading'
    },
    {
        name:'assets',
        titleAr:'إدارة الاصول',
        titleEn:'Assets',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img: 'https://img.freepik.com/free-photo/office-with-documents-money-accounts_144627-33571.jpg?t=st=1708926369~exp=1708929969~hmac=019622ae2c224d4a149e6a65d7faeafbf1e1aafc8b8ea65dc6c4206b1e4c40d1&w=1060',
        icon:'icons8-asset-100.png',
        baseUrl:'https://assets.absat.org',
        status:'loading'
    },
    {
        name:'financial',
        titleAr:'الحسابات المالية',
        titleEn:'Financial',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img: 'https://img.freepik.com/free-photo/hand-holding-bitcoin-money-stack_23-2148285274.jpg?t=st=1708926705~exp=1708930305~hmac=4eafd328eb1d273e63c16fac11f574ad89a34d87564f12aa1817734dfdbdc3c7&w=1060',
        icon: 'icons8-financial-96.png',
        baseUrl:'https://financial.absat.org',
        status:'loading'
    },
    {
        name:'production',
        titleAr:'خطوط الانتاج - التشغيل',
        titleEn:'operations',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img: 'https://img.freepik.com/free-photo/confident-caucasian-adult-builder-man-uniform-writes-clipboard-with-pencil-orange_141793-69350.jpg?t=st=1708927080~exp=1708930680~hmac=14a7ed3d11138779127b83f119c55153ece45d2426ff2a9561e3bf7bc6bfa0d0&w=1060',
        icon: 'icons8-operation-64.png',
        baseUrl:'https://production.absat.org',
        status:'done'
    },
    {
        name:'pos',
        titleAr:'نقاط البيع',
        titleEn:'Ponit Of Sale',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img: 'https://img.freepik.com/free-vector/money-transfer-realistic-composition-with-credit-cards-payment-terminal-laptop-cash-blue_1284-28205.jpg?t=st=1708942289~exp=1708945889~hmac=cf2b181519758126da718fe39172201b2ca7d4352dd8fd7af4b8963d24865f7f&w=1060',
        icon: 'icons8-cash-register-100.png',
        baseUrl:'https://pos.absat.org',
        status:'loading'
    },
    {
        name:'stocks',
        titleAr:'المستودعات',
        titleEn:'Stocks',
        descAr:'نظام أبسط مبيعات بساطها عليك في كل شي يخص الفاتورة الالكترونية وربطها مع هيئة الزكاة والدخل  وتوفير التقرير التي تساعدك في تحقيق اهدافك ',
        descEn:'',
        img: 'https://img.freepik.com/free-vector/delivery-logistics-shipment-isometric-composition-with-faceless-human-characters-two-male-workers-parcel-rack-illustration_1284-29116.jpg?t=st=1708942676~exp=1708946276~hmac=551725644dc66c13b15e309ea47dc668cebaff1a98b23ce983227eda9727bbd6&w=1060',
        icon: 'icons8-stock-64.png',
        baseUrl:'https://stocks.absat.org',
        status:'loading'
    },
];