import React from 'react' 
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


// Custom hooks
import { useTenant } from '../hooks'
import { APPS } from '../constants/AbsatApps'

const MenuSide = ({openMenu,setOpenMenu}) => {
    const [t] = useTranslation('global')
    const {tenantUsername} = useTenant();
    const _token = localStorage.getItem('token');
    
    return (
        <div 
            className={`  z-50   flex items-center   transition-all duration-500  ease-out  h-screen      primary-bg-color border-b    ${openMenu ? 'fixed px-3 w-9 flex md:w-40 md:relative ' : 'fixed w-0 hidden md:flex md:w-12 md:px-3  '}`}>
                <ul className=' cursor-default  flex  flex-col gap-5 items-center  text-white  text-xs  '>
                    {
                        APPS.map(app =>(
                            <li key={app.name} className={`w-full  menu-border-color   border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                                <NavLink to={app.status ==='done' ? `${app.baseUrl}/${tenantUsername}/token/${_token}` : '#' }  end >
                                        <div className={`flex items-center  gap-2 `}>
                                            <img src={`/assets/icons/${app?.icon}`} className={` w-6 hover:w-8 `} />
                                            { openMenu && <div className=' pt-2 whitespace-nowrap hidden  md:flex'>{app.titleAr}</div>}
                                        </div>
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
        </div>
    )
}

export default MenuSide