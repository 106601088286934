import React, { useEffect, useState } from 'react'
import axios from 'axios';

const withTenant = (Component) => {

    const Wrapper =  (props)=>{ 
        //console.log('withTenant: => Starting');
        const pathname = window.location.pathname;
        const usernameTenant  = pathname.split('/')[1];
        const tenant = JSON.parse(localStorage.getItem('tenant'));
        const [isTenant,setIsTenant] = useState(false);

        const getTenant = async() => {
            if(!tenant) {
                try{
                    const res = await axios.get(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/username/${usernameTenant}`);
                    const _data = res?.data;
                    if(_data){
                        localStorage.setItem('tenant',JSON.stringify( _data?.data));
                        setIsTenant(true);
                    }else{
                        window.location.replace(`${process.env.REACT_APP_Auth_WEB_BASE_URL}`)
                    }
                }catch(err){
                    console.log(err);
                    window.location.replace(`${process.env.REACT_APP_Auth_WEB_BASE_URL}`)
                }
            }else{
                setIsTenant(true);
            }
        }
        useEffect(()=>{
           
            getTenant();
        },[]);

        if(isTenant){
            //console.log('withTenant: => is Ready');
            return   <Component {...props} /> 
        }
        

    }
    return Wrapper;
}

export default withTenant