import axios from 'axios';
import {useEffect, useState} from 'react'

const useFetch = (url,autoLoad=true) => {
    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError]  = useState(null);
    const token = localStorage.getItem('token');

    const fetchData = async ()=>{
        try {
            const response = await axios.get(url,{
                headers:{
                    'Authorization': `Bearer ${token}` 
                }
            });
            setData(response.data);
            setLoading(false);
        }catch(error){
            setError(error);
            setLoading(false);
        }
    };

    const refreshHandler = () => {
        fetchData();
    }

    useEffect(()=>{
        if(autoLoad){
            fetchData();
            return () => {
                // Cleanup or cancel ongoing requests if needed
            }
        }
    },[url])

    return {data,loading,error,fetchData,refreshHandler}
}

export default useFetch