import { useEffect,useState } from "react";
import useFetch from "./useFetch";
import usePost from "./usePost";
import useCurrent from "./useCurrent";


const useLicense = (withCreate=true) =>{
    const {currentTenantId:tenantId} = useCurrent();
    const {data,loading} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/licenses/tenant/${tenantId}`);
    const {data:resultPost,postData} = usePost();
    const [isCreated,setIsCreated] = useState(false);

    const TRAIL_PERIOD_DAYS = 14;
    const [licenseDays,setLicenseDays] = useState(TRAIL_PERIOD_DAYS);
    const [typeLicense , setTypeLicense] = useState('Trail');

    const createLicense = () =>{
        const date = new Date();
        const startDate = new Date();
        const endDate = new Date(date.setDate( date.getDate() + 14 ))
        const _data = {
            tenantId: tenantId,
            companiesCount:1,
            branchesCount:1,
            usersCount:1,
            appIds: ['SALES'] ,
            typeLicense:'Trail',
            startDate: startDate,
            endDate:  endDate,
        };
        postData(`${process.env.REACT_APP_Auth_API_BASE_URL}/licenses`,_data)
        setIsCreated(true)
    }

    useEffect(() => {
        if(resultPost){
            console.log('License Created Success')
        }
    },[resultPost]);

    useEffect(()=>{
        if(data?.data){
            if(data.data.length === 0 && !isCreated && withCreate){
                createLicense();
            }else{
                setTypeLicense(data?.data[0]?.typeLicense || 'Trail')
                    const daysDiff =  Math.abs( new Date() - new Date(data.data[0].endDate) ) ;
                    if(daysDiff){
                        setLicenseDays(Math.round(daysDiff / 86400000));
                    }
                
            }
        }
    },[data]);

    return {data,loading,licenseDays,TRAIL_PERIOD_DAYS,typeLicense}
} 


export default useLicense;